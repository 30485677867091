<div class="sports-loading" *ngIf="sports===null">Loading list of sport events...</div>

<div class="sports" *ngIf="sports!==null">

	<div class="sport" *ngFor="let sport of sports">
		<button class="name {{sport.abbr}}">
			<span class="sport-abbr">{{sport.abbr | uppercase}}</span>
			<span class="supported-by">Includes<br/><b>{{sport.site_count | i18nPlural : siteCountMapping }}</b></span>
			<span class="enter-text">
				<i class="material-icons">play_arrow</i>
				<span>Enter Sport</span>
			</span>
		</button>
		<div class="events">
			<button>
				<span class="teams">{{sport.abbr | uppercase}} Network</span>
				<span class="streamers">Being Streamed By 2 Sites</span>
			</button>

			<header>Upcoming Events: <span class="date" *ngIf="sport.games && sport.games.length > 0">{{ sport.games[0].start_datetime | date:"fullDate":"-0400" }}</span></header>
			<button *ngFor="let game of sport.games">
				<small class="time">{{ game.start_datetime | date:"h:mm a":"-0400" }}</small>
				<span class="teams">{{ game.away_team }} @ {{ game.home_team }}</span>
			</button>
			<!--footer>Show 9 More Events</footer-->
		</div>
	</div>

</div>
