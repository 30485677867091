import { Component, OnInit } from '@angular/core';
import {
	HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest
}  from '@angular/common/http';
import { Observable, throwError, of, Subject }       from 'rxjs';
import {
	delay, map, tap, last, catchError, retry
}  from "rxjs/operators";

import { DomSanitizer, SafeUrl }  from '@angular/platform-browser';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	is_expanded : boolean = false;

	user = null;
	constructor( private sanitizer:DomSanitizer, private http: HttpClient ){}

	ngOnInit(){
		this.http.get( 'https://availablestreams.com/api/profile/whoami' ).subscribe( r => this.user = r )
	}

	qrcode_src = null; qrcode_loading = false;
	get_login_qrcode(){
		this.qrcode_loading = true;
		this.http.get('https://availablestreams.com/api/profile/get_login_code_url?qrcode').subscribe(
			(r) => { this.qrcode_src = this.sanitizer.bypassSecurityTrustUrl( r['url'] ); this.qrcode_loading = false; }
		)
	}

}
