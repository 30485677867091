import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { AvailablestreamsComponent } from './availablestreams/availablestreams.component';
import { SportsComponent } from './sports/sports.component';

const routes: Routes = [
	{ path: '',              redirectTo: '/sports', pathMatch: 'full' },

	{ path: 'sports',        component: SportsComponent },
	{ path: 'sports/:sport', component: SportsComponent },
];


@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
