<div class="background-image">
	<img src="/assets/background-hq.jpg" alt="" class="high-quality" (load)="hqimg.classList.add('ready')" #hqimg/>
	<img src="/assets/background-lq.jpg" alt="" class="low-quality"/>
</div>


<header>
	<div class="logo">
		<img src="/assets/availablestreams-logo.svg" />
		<p>
			<span>Available</span><br/>
			<span>Streams</span>
		</p>
	</div>
	<div class="space">UNDER CONSTRUCTION</div>
	<app-profile></app-profile>
</header>

<nav class="categories">
	<button class="category active" routerLink="/sports/">Sports</button>
	<button class="category soon">Movies</button>
	<button class="category soon">Cartoons</button>
	<button class="category soon">TV Shows</button>
</nav>

<div class="router-wrapper">
	<router-outlet></router-outlet>
</div>

<footer>
	<div class="users-info">
		<span>Total Views: ....</span>
		<span>Online Viewers: ...</span>
	</div>
	<div class="avst-info">
		<img src="/assets/availablestreams-logo.svg" />
		<span class="material-icons">add</span>
		<img src="/assets/replay-icon.png" />
	</div>
	<div class="views-info">
		<img src="/assets/ios-logo.png" />
		<img src="/assets/android-logo.png" />
		<img src="/assets/smarttv-logo.png" />
		<img src="/assets/ps4-logo.png" />
		<img src="/assets/xbox-logo.png" />
	</div>
</footer>
