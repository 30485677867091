<button *ngIf="user === null" class="loading discord">
	<img src="/assets/discord-logo.svg"/>
	<span>looking for you...</span>
</button>
<a *ngIf="user === false" class="discord" target="_blank" href="https://discord.com/api/oauth2/authorize?client_id=725953156734779453&redirect_uri=https%3A%2F%2Favailablestreams.com%2Fapi%2Fprofile%2Fdiscord_oauth_redirect&response_type=code&scope=identify%20email">
	<img src="/assets/discord-logo.svg"/>
	<span>login with discord</span>
</a>
<button *ngIf="user" class="user-info" (click)="is_expanded = !is_expanded">
	<img [src]="user.avatar_url"/>
	<span>{{ user.username }}<br/><small>{{ is_expanded ? 'Collapse' : 'Expand' }} profile</small></span>
</button>


<div class="login-qr-code expandable" [class.shown]="is_expanded" [class.loading]="qrcode_loading">
	<img [src]="qrcode_src || '/assets/qrcode-sample.svg'" [class.qrcode_is_ready]="qrcode_src"/>
	<button (click)="get_login_qrcode()"><span>Show QR Code</span></button>
	<footer>Quick Login Your Other Devices</footer>
</div>
