import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest }  from '@angular/common/http';
import { Observable, throwError, of, Subject }       from 'rxjs';
import { delay, map, tap, last, catchError, retry }  from "rxjs/operators";
import { I18nPluralPipe }    from '@angular/common';

@Component({
	selector: 'app-sports',
	templateUrl: './sports.component.html',
	styleUrls: ['./sports.component.scss']
})
export class SportsComponent implements OnInit {

	constructor( private http: HttpClient ){}
	siteCountMapping:  {[k: string]: string} = {'=0': 'No Sites!',  '=1': '1 Site',  'other': '# Sites' };


	sports = null;
	ngOnInit(): void {
		this.http.get( 'https://availablestreams.com/api/stream/get_sports' ).subscribe( s => this.sports = s )
	}

}
